const HOST = window.API_URL || "https://api.x-players.com/";

const RESELLER_HOST = window.RESELLER_URL || "http://reseller.x-players.com/";

const PLAYLIST_IS_PROTECTED = window.PLAYLIST_IS_PROTECTED;

const HIDE_VOUCHER_CODE_BUTTON = window.hide_voucher_code_button;
const HIDE_UPLOAD_PLAYLIST_OTHER_TAB = window.hide_upload_playlist_other_tab = true;
const HIDE_UPLOAD_PLAYLIST_EPG_URL = window.hide_upload_playlist_epg_url = true;

const API_HOST = `${HOST}api/`;

export {
    API_HOST,
    HOST,
    RESELLER_HOST,
    PLAYLIST_IS_PROTECTED,
    HIDE_VOUCHER_CODE_BUTTON,
    HIDE_UPLOAD_PLAYLIST_OTHER_TAB,
    HIDE_UPLOAD_PLAYLIST_EPG_URL
};
