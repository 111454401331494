import React, { useEffect, useState } from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import REQUESTS from '../../../api/requests';

import styles from "../style/payment.module.scss"

const loadAmazonCheckoutScript = (onLoad, amazon_region = "US") => {
    const servers = {
        "US": 'https://static-na.payments-amazon.com/checkout.js',
        "EU": "https://static-eu.payments-amazon.com/checkout.js",
        "UK": "https://static-eu.payments-amazon.com/checkout.js",
        "JP": "https://static-fe.payments-amazon.com/checkout.js",
    }
    const script = document.createElement('script');
    script.src = servers[amazon_region]
    script.async = true;

    script.onload = onLoad;

    document.body.appendChild(script);

    return () => document.body.removeChild(script);
};
export default function AmazonPayCheckout({ data = {}, amazon_region = "US" }) {
    const [amazonConfig, setAmazonConfig] = useState(null);

    const fetchAmazonConfig = () => {
        const { package_id, device_mac } = data;

        if (!package_id || !device_mac) return;

        const query = {
            device_mac,
            package_id,
            type: "device_subscription"
        };

        setAmazonConfig(null)

        REQUESTS.GET_AMAZON_CONFIG(
            query,
            (response) => {
                setAmazonConfig(response);
            },
        );
    };

    const renderAmazonPayButton = () => {
        if (!window.amazon || !window.amazon.Pay) {
            console.error('Amazon Pay SDK not loaded.');
            return;
        }

        window.amazon.Pay.renderButton('#AmazonPayButton', {
            ...amazonConfig,
        });
    };

    useEffect(() => {
        fetchAmazonConfig();
    }, [data]);

    useEffect(() => {
        if (amazonConfig) {
            loadAmazonCheckoutScript(() => {
                renderAmazonPayButton();
            }, amazon_region);
        }
    }, [amazonConfig]);

    return <div className={styles['button-container']}>
        {!amazonConfig ?
            <Spin
                indicator={<LoadingOutlined
                    style={{
                        fontSize: 24,
                    }}
                    spin
                />}
            /> :
            <div id="AmazonPayButton" className={styles['amazon-pay']} />}
    </div>
}
