import { useState } from "react";
import PropTypes from "prop-types";

import { Form, Input } from "antd";

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";
import REQUESTS from "../../api/requests";

import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import TitleComponent from "../../components/TitleComponent";
import ModalComponent from "../../components/ModalComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ValidateMacAddress from "../../components/ValidateMacAddress";
import "./_style.scss";
/**
 *  The modal is intended for Lg and Samsung QA teams, so that they can test the application.
 *  For activation, they must input the device mac address which is confirmed after validation, and voucher code which we provide to the LG and Samsung QA teams through documentation.
 *  After request is shown success or error modal.
 *
 * @param {bool} visible     For showing the modal
 * @param {func} onClose     For closing the modal
 *
 * @returns Modal
 */

//test
export default function ActivateDeviceModal({ visible, onClose }) {
  const translation = useTranslation();

  const [loading, setLoading] = useState(false);

  const [macIsValid, setMacIsValid] = useState(false);
  const [macValue, setMacValue] = useState("");

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.activation?.[text]) {
      return translation?.pages?.activation?.[text];
    }

    return TEXT?.pages?.activation?.[text];
  };

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      code: values.code,
      mac: macValue,
    };

    function callback() {
      setLoading(false);

      setSuccessMessage(handleText("qa_modal_success_message"));
    }

    function errorCallback(error) {
      setLoading(false);
      setErrorMessage(error.message);
    }

    REQUESTS.ACTIVATE_VOUCHER_CODE(body, callback, errorCallback);
  };

  return (
    <>
      <ModalComponent visible={visible} onCancel={onClose}>
        <div className="activate-device-modal">
          <TitleComponent>{handleText("qa_modal_title")}</TitleComponent>

          <Form
            form={form}
            layout="vertical"
            style={{ width: 300, marginTop: 40, color: "black" }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <ValidateMacAddress
              value={macValue}
              setMacIsValid={setMacIsValid}
              setValue={setMacValue}
            />
            <Form.Item
              rules={[
                {
                  required: true,
                  message: handleText("qa_modal_voucher_code_input_message"),
                },
              ]}
              name="code"
            >
              <Input
                className="activate-device-modal__voucher-code"
                placeholder={handleText(
                  "qa_modal_voucher_code_input_placeholder"
                )}
              />
            </Form.Item>

            <div style={{ marginTop: "40px", textAlign: "center" }}>
              <ButtonComponent
                type="primary"
                loading={loading}
                disabled={!macIsValid}
                onClick={() => form.submit()}
                title={handleText("qa_modal_button_title")}
              />
            </div>
          </Form>
        </div>
      </ModalComponent>

      <SuccessModal
        message={successMessage}
        visible={successMessage ? true : false}
        onClose={() => {
          onClose();
          setSuccessMessage(null);
        }}
      />
      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
}

ActivateDeviceModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
