import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Checkbox } from "antd";

import ReCAPTCHA from "react-google-recaptcha";

import useTranslation from "../../hooks/useTranslation";

import TEXT from "../../config/text";

import { PLAYLIST_IS_PROTECTED, HIDE_UPLOAD_PLAYLIST_EPG_URL } from "../../config";

import REQUESTS from "../../api/requests";

import ErrorModal from "../../components/ErrorModal";
import SuccessModal from "../../components/SuccessModal";
import InputComponent from "../../components/InputComponent";
import ButtonComponent from "../../components/ButtonComponent";
import ValidateMacAddress from "../../components/ValidateMacAddress";

/**
 *  Upload playlist by url. Entering in the user's device mac address, playlist name, and playlist URL
 *  After request is shown success or error modal.
 */

export default function UploadByUrl({ captchaSiteKey }) {
  const translation = useTranslation();

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(false);

  const [macIsValid, setMacIsValid] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [successMessage, setSuccessMessage] = useState(null);

  const [defaultMac, setDefaultMac] = useState(null);

  const [macValue, setMacValue] = useState("");

  const [playlistName, setPlaylistName] = useState("");

  const [isProtected, setIsProtected] = useState(PLAYLIST_IS_PROTECTED);

  const [recaptchaValue, setRecaptchaValue] = useState(false);

  const [form] = Form.useForm();

  const handleText = (text) => {
    if (translation?.pages?.upload_playlist?.[text]) {
      return translation?.pages?.upload_playlist?.[text];
    }

    return TEXT?.pages?.upload_playlist?.[text];
  };

  const onFinish = (values) => {
    setLoading(true);

    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("mac", macValue);
    formData.append("url", values.url);


    formData.append("is_protected", isProtected);

    if (isProtected) {
      formData.append("pin", values.pin);
      formData.append("confirm_pin", values.confirm_pin);
    }

    if (values.epg_url) {
      formData.append("epg_url", values.epg_url);
    }

    function callback() {
      setLoading(false);

      setMacValue("");
      setMacIsValid(false);
      setRecaptchaValue(false);

      form.resetFields();

      setIsProtected(PLAYLIST_IS_PROTECTED);

      setSuccessMessage(handleText("success_message"));
    }

    function errorCallback(error) {
      setLoading(false);
      setErrorMessage(error.message);
    }

    REQUESTS.UPLOAD_PLAYLIST(formData, callback, errorCallback);
  };

  const handleCaptchaChange = (value) => {
    REQUESTS.CAPTCHA.SENT_CAPTCHA_TOKEN(
      { token: value },
      (message) => {
        setRecaptchaValue(message.success);
      },
      (err) => setRecaptchaValue(false)
    );
  }

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.name) {
      setPlaylistName(changedValues.name);
    }
  };

  useEffect(() => {
    if (playlistName && playlistName.length > 30) {
      setErrorMessage(handleText('error_message'));
    }
  }, [playlistName]);

  useEffect(() => {
    if (searchParams.get("mac")) {
      setDefaultMac(searchParams.get("mac"));
      setMacValue(searchParams.get("mac"));
    }
  }, [searchParams.get("mac")]);

  return (
    <>
      <Form
        autoComplete="off"
        name="upload-playlist-by-url"
        form={form}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <ValidateMacAddress
          defaultMac={defaultMac}
          setMacIsValid={setMacIsValid}
          reset={successMessage}
          value={macValue}
          setValue={setMacValue}
        />

        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: handleText("playlist_name_message"),
            },
          ]}
        >
          <InputComponent placeholder={handleText("playlist_name")} />
        </Form.Item>

        <Form.Item
          name="url"
          rules={[
            {
              required: true,
              message: handleText("playlist_url_message"),
            },
            {
              validator: (rule, value, callback) => {
                if (value && !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value)) {
                  callback('Please enter a valid URL');
                } else {
                  callback();
                }
              }
            },
          ]}
        >
          <InputComponent placeholder={handleText("playlist_url")} />
        </Form.Item>
        {!HIDE_UPLOAD_PLAYLIST_EPG_URL &&
          <Form.Item
            name="epg_url"
            rules={[
              {
                validator: (rule, value, callback) => {
                  if (value && !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(value)) {
                    callback('Please enter a valid URL');
                  } else {
                    callback();
                  }
                }
              },
            ]}
          >
            <InputComponent placeholder={handleText("playlist_epg_url")} />
          </Form.Item>}

        {!PLAYLIST_IS_PROTECTED && <Checkbox
          className="protected"
          checked={isProtected}
          onChange={(e) => setIsProtected(e.target.checked)}>
          {handleText("protect_playlist")}
        </Checkbox>}

        <Form.Item
          name="pin"
          rules={[
            {
              required: isProtected,
              message: handleText('password_message'),
            },
          ]}
          hasFeedback
        >
          <InputComponent
            type="password"
            placeholder={handleText("set_pin")}
            disabled={!isProtected}
          />
        </Form.Item>

        <Form.Item
          name="confirm_pin"
          dependencies={["pin"]}
          hasFeedback
          rules={[
            {
              required: isProtected,
              message: handleText('confirm_password_input_message'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("pin") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    handleText('confirm_password_input_message_2')
                  )
                );
              },
            }),
          ]}
        >
          <InputComponent
            type="password"
            placeholder={handleText("confirm_pin")}
            disabled={!isProtected}
          />
        </Form.Item>

        {
          captchaSiteKey && <ReCAPTCHA
            sitekey={captchaSiteKey}
            onChange={handleCaptchaChange}
          />
        }

        <div style={{ marginTop: "40px", textAlign: "center" }}>
          <ButtonComponent
            disabled={captchaSiteKey ? (!macIsValid || !recaptchaValue) : !macIsValid}
            loading={loading}
            onClick={() => form.submit()}
            title={handleText("url_button_title")}
          />
        </div>
      </Form>

      <SuccessModal
        message={successMessage}
        visible={successMessage ? true : false}
        onClose={() => setSuccessMessage(null)}
      />

      <ErrorModal
        message={errorMessage}
        visible={errorMessage ? true : false}
        onClose={() => setErrorMessage(null)}
      />
    </>
  );
}
