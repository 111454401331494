export const formatDate = (date) => {
    if (date) {
        const dateArr = date.split("T")[0].split("-");
        return `${dateArr[2]}.${dateArr[1]}.${dateArr[0]}`;
    }
};

export const fetchIpApiData = async () => {

    const datafromSessionStorage = sessionStorage.getItem('ipData');

    if (datafromSessionStorage) {
        return JSON.parse(datafromSessionStorage);
    }

    try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        sessionStorage.setItem('ipData', JSON.stringify(data));
        return data;
    } catch (error) {
        console.error('Error fetching IP data:', error);
        return null;
    }
};